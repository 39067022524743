var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.slug, attrs: { id: "map-list" } },
    [
      _vm.loading
        ? _c("b-row", { attrs: { cols: "12" } }, [_c("spinner")], 1)
        : _c(
            "b-row",
            { staticClass: "mx-0" },
            [
              _c(
                "b-col",
                { staticClass: "map-heading", attrs: { cols: "12" } },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-popover",
                            rawName: "v-b-popover.hover.top",
                            value:
                              "Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo",
                            expression:
                              "'Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo'",
                            modifiers: { hover: true, top: true },
                          },
                        ],
                      },
                      [
                        _c("SvgInfoCircle"),
                        _vm._v(
                          " " + _vm._s(_vm.geo.label) + "\n                "
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.property
                    ? _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              _vm.mixinGoTo("property", {
                                propertyBand: _vm.property.band,
                                propertyId: _vm.propertyId.toString(),
                                propertyPad: _vm.property,
                                propertyName: _vm.property.name,
                                newWindow: false,
                              })
                            },
                          },
                        },
                        [
                          _c("BtnBack", {
                            attrs: { text: "Torna alla struttura" },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.mixinGoTo("search", {})
                            },
                          },
                        },
                        [_c("BtnBack", { attrs: { text: "Vai alla lista" } })],
                        1
                      ),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "map-wrapper",
                  attrs: { cols: "12", xl: "8", xxl: "9", "order-xl": "2" },
                },
                [
                  _c(
                    "GmapMap",
                    {
                      ref: "map_list",
                      staticStyle: { width: "100%" },
                      attrs: {
                        center: {
                          lat: _vm.geo.latitude,
                          lng: _vm.geo.longitude,
                        },
                        zoom: 12,
                        options: {
                          mapStyle: true,
                          gestureHandling: "greedy",
                        },
                        "map-type-id": "roadmap",
                      },
                      on: { click: _vm.clickedMap },
                    },
                    [
                      this.paths.length > 0
                        ? _c("gmap-polygon", {
                            ref: "polygon",
                            attrs: {
                              paths: this.paths,
                              editable: false,
                              stroke: "#dedede",
                              options: {
                                strokeColor: "#EC7A60",
                                strokeOpacity: 0.5,
                                strokeWeight: 1,
                                fillColor: "#EC7A60",
                                fillOpacity: 0.05,
                              },
                            },
                            on: { click: _vm.clickedMap },
                          })
                        : _vm._e(),
                      _vm._l(_vm.markers, function (m, index) {
                        return _c("gmap-marker", {
                          key: index,
                          attrs: {
                            position: m.position,
                            icon: m.icon,
                            zIndex: m.zIndex,
                            clickable: _vm.markers.length > 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clickedMarker({
                                propertyId: m.propertyId,
                                slideIndex: index,
                              })
                            },
                          },
                        })
                      }),
                      _vm.showInfo
                        ? _c("GmapCircle", {
                            ref: "circle",
                            attrs: {
                              center: {
                                lat: _vm.geo.latitude,
                                lng: _vm.geo.longitude,
                              },
                              radius: 5000,
                              options: {
                                strokeColor: "#1d23ec",
                                strokeOpacity: 0.5,
                                strokeWheight: 1.5,
                                fillColor: "#1d23ec",
                                fillOpacity: 0.2,
                              },
                            },
                            on: { click: _vm.clickedMap },
                          })
                        : _vm._e(),
                      _c(
                        "gmap-info-window",
                        {
                          ref: "infoWindow",
                          attrs: {
                            options: { maxWidth: 300 },
                            position: {
                              lat: _vm.geo.latitude,
                              lng: _vm.geo.longitude,
                            },
                            opened: _vm.showInfo,
                          },
                          on: {
                            closeclick: function ($event) {
                              _vm.showInfo = false
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { variant: "secondary" },
                                  on: { click: _vm.selectPoint },
                                },
                                [_vm._v("Cerca nei paraggi")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "slick-wrapper",
                  attrs: { cols: "12", xl: "4", xxl: "3", "order-xl": "1" },
                  on: { wheel: _vm.scrollSlider },
                },
                [
                  _vm.properties.length > 0
                    ? _c(
                        "div",
                        { staticClass: "slick-container" },
                        [
                          _c(
                            "Slick",
                            {
                              ref: "slick",
                              attrs: { options: _vm.slickOptions },
                              on: {
                                afterChange: _vm.slick__handleAfterChange,
                                beforeChange: _vm.slick__handleBeforeChange,
                                breakpoint: _vm.slick__handleBreakpoint,
                                destroy: _vm.slick__handleDestroy,
                                edge: _vm.slick__handleEdge,
                                init: _vm.slick__handleInit,
                                reInit: _vm.slick__handleReInit,
                                setPosition: _vm.slick__handleSetPosition,
                                swipe: _vm.slick__handleSwipe,
                                lazyLoaded: _vm.slick__handleLazyLoaded,
                                lazyLoadError: _vm.slick__handleLazeLoadError,
                              },
                            },
                            _vm._l(_vm.properties, function (property, index) {
                              return _c(
                                "div",
                                { key: "slide-" + index },
                                [
                                  _c("PropertyPad", {
                                    attrs: { property: property, index: index },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _vm.properties.length > 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "slick-arrow",
                                  attrs: { variant: "light", id: "slick-prev" },
                                },
                                [_c("SvgChevronLeft")],
                                1
                              )
                            : _vm._e(),
                          _vm.properties.length > 1
                            ? _c(
                                "span",
                                {
                                  staticClass: "slick-arrow",
                                  attrs: { variant: "light", id: "slick-next" },
                                },
                                [_c("SvgChevronRight")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c("PropertyPad", {
                        attrs: { property: _vm.property, index: 1 },
                      }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }