<template>
    <div :class="slug" id="map-list">

        <b-row cols="12" v-if="loading">
            <spinner />
        </b-row>

        <b-row class="mx-0" v-else>
            <b-col cols="12" class="map-heading">
                <div>
                    <span v-b-popover.hover.top="'Puoi cliccare su un qualsiasi punto della mappa per cercare in quel luogo'" >
                        <SvgInfoCircle /> {{geo.label}}
                    </span>
                </div>
                <span v-if="property" @click="mixinGoTo('property',{propertyBand: property.band, propertyId: propertyId.toString(), propertyPad: property, propertyName: property.name, newWindow: false})">
                    <BtnBack :text="'Torna alla struttura'" />
                </span>
                <span v-else @click="mixinGoTo('search',{})">
                    <BtnBack :text="'Vai alla lista'" />
                </span>
            </b-col>

            <b-col cols="12" xl="8" xxl="9" order-xl="2" class="map-wrapper">
                <GmapMap
                    ref="map_list"
                    :center="{lat:geo.latitude, lng:geo.longitude}"
                    :zoom="12"
                    :options="{
                      mapStyle: true,
                      gestureHandling: 'greedy',
                    }"
                    map-type-id="roadmap"
                    style="width: 100%;"
                    @click="clickedMap"
                >
                    <gmap-polygon
                        v-if="this.paths.length > 0"
                        :paths="this.paths"
                        :editable="false"
                        stroke="#dedede"
                        ref="polygon"
                        :options="{
                            strokeColor: '#EC7A60',
                            strokeOpacity: 0.5,
                            strokeWeight: 1,
                            fillColor: '#EC7A60',
                            fillOpacity: 0.05
                        }"

                        @click="clickedMap"
                    />
                    <gmap-marker
                        v-for="(m, index) in markers"
                        :key="index"
                        :position="m.position"
                        :icon="m.icon"
                        :zIndex="m.zIndex"
                        :clickable="(markers.length > 1)"
                        @click="clickedMarker({propertyId: m.propertyId,slideIndex:index})"
                    />
                    <GmapCircle
                        v-if="showInfo"
                        ref='circle'
                        :center="{lat:geo.latitude, lng:geo.longitude}"
                        :radius="5000"
                        :options="{
                          strokeColor:'#1d23ec',
                          strokeOpacity:.5,
                          strokeWheight:1.5,
                          fillColor:'#1d23ec',
                          fillOpacity:.2
                        }"

                        @click="clickedMap"
                    />
                    <gmap-info-window
                        ref="infoWindow"
                        :options="{maxWidth: 300}"
                        :position="{lat:geo.latitude, lng:geo.longitude}"
                        :opened="showInfo"
                        @closeclick="showInfo=false">
                        <div>
                            <b-button variant="secondary" @click="selectPoint">Cerca nei paraggi</b-button>
                        </div>
                    </gmap-info-window>


                </GmapMap>
            </b-col>

            <b-col cols="12" xl="4" xxl="3" order-xl="1" class="slick-wrapper" @wheel="scrollSlider">
                <div class="slick-container" v-if="properties.length > 0">
                    <Slick
                        ref="slick"
                        :options="slickOptions"
                        @afterChange="slick__handleAfterChange"
                        @beforeChange="slick__handleBeforeChange"
                        @breakpoint="slick__handleBreakpoint"
                        @destroy="slick__handleDestroy"
                        @edge="slick__handleEdge"
                        @init="slick__handleInit"
                        @reInit="slick__handleReInit"
                        @setPosition="slick__handleSetPosition"
                        @swipe="slick__handleSwipe"
                        @lazyLoaded="slick__handleLazyLoaded"
                        @lazyLoadError="slick__handleLazeLoadError"
                    >
                        <div
                            v-for="(property,index) in properties"
                            :key="'slide-'+index"
                        >
                            <PropertyPad
                                :property="property"
                                :index="index"
                            />
                            <!--<a class="pad-wrapper" :data-slide-index="index" @click="clickedPad"></a>-->
                        </div>
                    </Slick>
                    <span v-if="properties.length > 1" variant="light" class="slick-arrow" id="slick-prev"><SvgChevronLeft /></span>
                    <span v-if="properties.length > 1" variant="light" class="slick-arrow" id="slick-next"><SvgChevronRight /></span>
                </div>

                <PropertyPad
                    v-else
                    :property="property"
                    :index="1"
                />

            </b-col>
        </b-row>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import Slick from 'vue-slick';
    import PropertyPad from '../blocks/property/property-pad-grid'
    import Spinner from '../atoms/spinner'
    import BtnBack from '../atoms/btn-back'
    import SvgInfoCircle from '../svg/info-circle'
    import Axios from "axios";
    import Vue from "vue";
    import SvgChevronRight from '../svg/chevron-right'
    import SvgChevronLeft from '../svg/chevron-left'

    export default {
        name: 'google-map',
        components:{
            Spinner,
            BtnBack,
            Slick,
            PropertyPad,
            SvgInfoCircle,
            SvgChevronLeft,
            SvgChevronRight,
        },
        props: {
            slug: String,
            title: String,
            propertyId: Number,
            property: Object
        },
        computed: mapGetters({
            initGeo:        'getGeo',
            initMap:        'getMap',
            availability:   'getAvailability',
            sortResults:    'getSortResults',
            activeBand:     'getActiveBand'
        }),
        data() {
            return {
                selectedGeo: {},
                geo: '',
                map: '',
                google: {},
                //activeBand: false,
                markers: [],
                properties: [],
                paths: [],
                loading: true,
                showInfo: false,
                defaultScaledSize: null,
                activeScaledSize: null,
                mapStyle:
                {
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    styles: 
                    [
                        // snazzy maps json here
                    ]
                },
                slickOptions: {
                    vertical: true,
                    verticalSwiping: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    prevArrow: '#slick-prev',
                    nextArrow: '#slick-next',

                    responsive: [{
                        breakpoint: 1200,
                        settings: {
                            adaptiveHeight:false,
                            centerMode: true,
                            //slidesToShow: 3,
                            variableWidth: false,
                            vertical: false,
                            verticalSwiping: false,
                        }
                    }]
                    
                    
                },
                here_apikey: Vue.prototype.$config.here.apikey,
                reverse_geocode_endpoint: Vue.prototype.$config.here.reverse_geocode_endpoint,
            }
        },
        beforeMount(){
        },
        mounted(){

            this.geo = this.initGeo
            this.map = this.initMap

            if(Object.values(this.map.geography)[0]){
              let coordinates = Object.values(this.map.geography)[0].polygon.coordinates
              for(var i = 0; i < coordinates[0].length; i++){
                  this.paths.push({lat: parseFloat(coordinates[0][i][1]), lng: parseFloat(coordinates[0][i][0])})
              }
            }

            this.$gmapApiPromiseLazy().then((google) => {

                this.google = google
                this.defaultScaledSize = new google.maps.Size(50,50)
                this.activeScaledSize = new google.maps.Size(50,50)

                let properties = this.activeBand ? (this.map.data)[this.activeBand] : (this.map.data)

                properties.forEach(property => {

                    // map detail
                    if(this.propertyId>0 && (this.propertyId == property.property_id))
                    {
                        this.addMarker(property)
                    }
                    // map list
                    else if(this.propertyId==0)
                    {
                        this.addMarker(property)

                    }

                })

                this.properties = [...this.markers]

                this.markers.push({
                    zIndex:             9999,
                    position:
                        {
                            lat: parseFloat(this.geo.latitude),
                            lng: parseFloat(this.geo.longitude)
                        },
                        icon: {
                            
                            url: 'data:image/svg+xml;base64,'+window.btoa('\
                                <svg width="70" height="120" viewBox="0 0 35 60" fill="none" xmlns="http://www.w3.org/2000/svg">\
                                    <path d="M34.1527 18.562L2.36867 0.211333C2.35067 0.200667 2.33 0.197333 2.312 0.188C2.22667 0.143333 2.138 0.106667 2.04467 0.0773335C2.01333 0.0673335 1.98333 0.056 1.95133 0.048C1.83133 0.0193334 1.708 0 1.57933 0C1.45067 0 1.32733 0.0193333 1.208 0.0486666C1.176 0.0566666 1.146 0.0680001 1.11467 0.0780001C1.02133 0.106667 0.932 0.143333 0.847333 0.188667C0.828667 0.198667 0.808668 0.202 0.790001 0.212C0.778001 0.219333 0.769334 0.23 0.757334 0.237333C0.650668 0.302667 0.549334 0.376667 0.461334 0.464667C0.459334 0.466667 0.458 0.468667 0.456 0.470667C0.371333 0.556 0.300667 0.653334 0.237334 0.756667C0.219334 0.786667 0.204001 0.816667 0.188001 0.848C0.144668 0.93 0.109334 1.016 0.0806669 1.106C0.0700002 1.13867 0.0586672 1.17067 0.0500005 1.204C0.0206672 1.32467 0 1.44933 0 1.57933V38.28V58.4213C0 59.2933 0.706667 60 1.57867 60C2.45067 60 3.15733 59.2933 3.15733 58.4213V39.1913L34.1513 21.2967C34.64 21.0147 34.9407 20.4933 34.9407 19.9293C34.942 19.3653 34.6413 18.8447 34.1527 18.562Z" fill="#f07b5d"/>\
                                </svg>'),
                            scaledSize: this.defaultScaledSize
                        }
                })


                if(this.properties.length <= 0){
                  // vado alla lista se non ci sono risultati
                  // (es. ho usato filtri di ricerca nella mappa che portano a non avere risultati)
                  this.mixinGoTo('search', {})
                }


                /* CENTRATURA MAPPA */
                // timeout necessario causa bug di vue google map
                let _this = this

                setTimeout(function(){

                  let map = _this.$refs['map_list']

                  if(_this.properties.length > 0){

                    let bounds = new google.maps.LatLngBounds()

                    for (let i = 0; i < _this.properties.length; i++) {
                      bounds.extend(
                          new google.maps.LatLng({lat: _this.properties[i].position.lat, lng: _this.properties[i].position.lng})
                      );
                    }

                    map.fitBounds(bounds)

                  }else{

                    // questo centra la mappa sulle coordinate della ricerca
                    // (se venisse escluso il mixinGoTo search poco sopra, per tornare sempre alla lista in caso
                    // non ci siano risultati)
                    // attenzione: gli inSalabam hanno coordinate geo "fittizie", per attivare questa funzionalità
                    // andrebbero prima gestiti ed impostati i centri in tutti gli inSalabam
                    map.setCenter(new google.maps.LatLng({lat: _this.geo.latitude, lng: _this.geo.longitude}))

                  }

                }, 350)

                /* centratura mappa */


                this.loading = false
            })            
        },
        methods:{
            googleMapInit(){
                
            },
            getMarker(live_price)
            {
                let markerColor = '#aaaaaa'

                if(live_price == 0)
                {
                    markerColor = '#28a745'
                }
                else
                {
                    let _this = this,
                        markerClass = _this.mixinGetIntegrationInfo(live_price).class
                    
                    if(markerClass == 'default') {markerColor = '#0077CA'}
                    if(markerClass == 'red') {markerColor = '#aaaaaa'}
                    //if(markerClass == 'red') {markerColor = '#dc3545'}
                    if(markerClass == 'gold') {markerColor = '#F9B03A'}
                }

                return 'data:image/svg+xml;base64,'+window.btoa('\
                    <svg width="60" height="76" viewBox="0 0 60 76" xmlns="http://www.w3.org/2000/svg">\
                    <path fill="'+markerColor+'" stroke="white" stroke-width="1" d="M30.3999 0.776978C22.5712 0.776978 15.0632 3.88691 9.52747 9.42261C3.99177 14.9583 0.881836 22.4663 0.881836 30.295C0.881836 46.02 27.3409 73.901 28.4679 75.082C28.7126 75.3364 29.0051 75.54 29.3286 75.6813C29.6521 75.8225 30.0004 75.8985 30.3534 75.905C30.7063 75.9115 31.0571 75.8484 31.3856 75.7192C31.7141 75.59 32.0139 75.3972 32.2678 75.152C32.2918 75.129 32.3149 75.106 32.3379 75.082C33.4649 73.901 59.9238 46.02 59.9238 30.295C59.9237 26.4182 59.16 22.5794 57.6761 18.9977C56.1923 15.4161 54.0175 12.1618 51.2759 9.42078C48.5343 6.67972 45.2796 4.50557 41.6976 3.02246C38.1157 1.53935 34.2767 0.776321 30.3999 0.776978ZM30.3999 43.712C27.7464 43.7116 25.1524 42.9243 22.9463 41.4498C20.7401 39.9753 19.0208 37.8798 18.0056 35.4281C16.9904 32.9764 16.725 30.2788 17.2429 27.6763C17.7608 25.0738 19.0388 22.6833 20.9153 20.8071C22.7918 18.9309 25.1824 17.6532 27.785 17.1357C30.3876 16.6181 33.0852 16.8839 35.5367 17.8995C37.9883 18.915 40.0837 20.6348 41.5579 22.8411C43.0321 25.0475 43.8188 27.6414 43.8188 30.295C43.819 32.0571 43.472 33.802 42.7976 35.4301C42.1233 37.0581 41.1348 38.5373 39.8887 39.7833C38.6426 41.0292 37.1633 42.0174 35.5352 42.6915C33.907 43.3656 32.1621 43.7124 30.3999 43.712Z"></path>\
                    </svg>')
            },
            addMarker(property)
            {
                let _this = this
                
                _this.markers.push({
                    zIndex:             1,
                    band:               property.band,
                    address1:           property.address1,
                    amenities:          property.amenities,
                    availableRooms:     property.availableRooms,
                    city:               property.city,
                    countryCode:        property.countryCode,
                    propertyId:         property.propertyId,
                    propertyRating:     property.propertyRating,
                    latitude:           property.latitude,
                    longitude:          property.longitude,
                    name:               property.name,
                    postalCode:         property.postalCode,
                    propertyAmenities:  property.propertyAmenities,
                    propertyCategory:   property.propertyCategory,
                    property_id:        property.property_id,
                    proximityDistance:  property.proximityDistance,
                    defaultSorting:     property.defaultSorting,
                    rate_id:            property.rate_id,
                    roomIdsWithKitchen: property.roomIdsWithKitchen,
                    room_id:            property.room_id,
                    room_name:          property.room_name,
                    salabamScore:       property.salabamScore,
                    score_description:  property.score_description,
                    shortDescription:   property.shortDescription,
                    thumbNailUrl:       property.thumbNailUrl,
                    total:              property.total,
                    hotelRating:        property.hotelRating,
                    live_price:         property.live_price,
                    position:
                        {
                            lat: parseFloat(property.latitude),
                            lng: parseFloat(property.longitude)
                        },
                        icon: {
                            url: _this.getMarker(property.live_price),
                            /*
                            unavailableMarkerIcon: 'data:image/svg+xml;base64,'+window.btoa('\
                                        <svg width="60" height="76" viewBox="0 0 60 76" xmlns="http://www.w3.org/2000/svg">\
                                        <path fill="#aaaaaa" stroke="white" stroke-width="1" d="M30.3999 0.776978C22.5712 0.776978 15.0632 3.88691 9.52747 9.42261C3.99177 14.9583 0.881836 22.4663 0.881836 30.295C0.881836 46.02 27.3409 73.901 28.4679 75.082C28.7126 75.3364 29.0051 75.54 29.3286 75.6813C29.6521 75.8225 30.0004 75.8985 30.3534 75.905C30.7063 75.9115 31.0571 75.8484 31.3856 75.7192C31.7141 75.59 32.0139 75.3972 32.2678 75.152C32.2918 75.129 32.3149 75.106 32.3379 75.082C33.4649 73.901 59.9238 46.02 59.9238 30.295C59.9237 26.4182 59.16 22.5794 57.6761 18.9977C56.1923 15.4161 54.0175 12.1618 51.2759 9.42078C48.5343 6.67972 45.2796 4.50557 41.6976 3.02246C38.1157 1.53935 34.2767 0.776321 30.3999 0.776978ZM30.3999 43.712C27.7464 43.7116 25.1524 42.9243 22.9463 41.4498C20.7401 39.9753 19.0208 37.8798 18.0056 35.4281C16.9904 32.9764 16.725 30.2788 17.2429 27.6763C17.7608 25.0738 19.0388 22.6833 20.9153 20.8071C22.7918 18.9309 25.1824 17.6532 27.785 17.1357C30.3876 16.6181 33.0852 16.8839 35.5367 17.8995C37.9883 18.915 40.0837 20.6348 41.5579 22.8411C43.0321 25.0475 43.8188 27.6414 43.8188 30.295C43.819 32.0571 43.472 33.802 42.7976 35.4301C42.1233 37.0581 41.1348 38.5373 39.8887 39.7833C38.6426 41.0292 37.1633 42.0174 35.5352 42.6915C33.907 43.3656 32.1621 43.7124 30.3999 43.712Z"></path>\
                                        </svg>'),
                            defaultMarkerIcon: 'data:image/svg+xml;base64,'+window.btoa('\
                                        <svg width="60" height="76" viewBox="0 0 60 76" xmlns="http://www.w3.org/2000/svg">\
                                        <path fill="#0077CA" stroke="white" stroke-width="1" d="M30.3999 0.776978C22.5712 0.776978 15.0632 3.88691 9.52747 9.42261C3.99177 14.9583 0.881836 22.4663 0.881836 30.295C0.881836 46.02 27.3409 73.901 28.4679 75.082C28.7126 75.3364 29.0051 75.54 29.3286 75.6813C29.6521 75.8225 30.0004 75.8985 30.3534 75.905C30.7063 75.9115 31.0571 75.8484 31.3856 75.7192C31.7141 75.59 32.0139 75.3972 32.2678 75.152C32.2918 75.129 32.3149 75.106 32.3379 75.082C33.4649 73.901 59.9238 46.02 59.9238 30.295C59.9237 26.4182 59.16 22.5794 57.6761 18.9977C56.1923 15.4161 54.0175 12.1618 51.2759 9.42078C48.5343 6.67972 45.2796 4.50557 41.6976 3.02246C38.1157 1.53935 34.2767 0.776321 30.3999 0.776978ZM30.3999 43.712C27.7464 43.7116 25.1524 42.9243 22.9463 41.4498C20.7401 39.9753 19.0208 37.8798 18.0056 35.4281C16.9904 32.9764 16.725 30.2788 17.2429 27.6763C17.7608 25.0738 19.0388 22.6833 20.9153 20.8071C22.7918 18.9309 25.1824 17.6532 27.785 17.1357C30.3876 16.6181 33.0852 16.8839 35.5367 17.8995C37.9883 18.915 40.0837 20.6348 41.5579 22.8411C43.0321 25.0475 43.8188 27.6414 43.8188 30.295C43.819 32.0571 43.472 33.802 42.7976 35.4301C42.1233 37.0581 41.1348 38.5373 39.8887 39.7833C38.6426 41.0292 37.1633 42.0174 35.5352 42.6915C33.907 43.3656 32.1621 43.7124 30.3999 43.712Z"></path>\
                                        </svg>'),
                            currentMarkerIcon: 'data:image/svg+xml;base64,'+window.btoa('\
                                        <svg width="60" height="76" viewBox="0 0 60 76" xmlns="http://www.w3.org/2000/svg">\
                                        <path fill="#FBB03A" stroke="white" stroke-width="1" d="M30.3999 0.776978C22.5712 0.776978 15.0632 3.88691 9.52747 9.42261C3.99177 14.9583 0.881836 22.4663 0.881836 30.295C0.881836 46.02 27.3409 73.901 28.4679 75.082C28.7126 75.3364 29.0051 75.54 29.3286 75.6813C29.6521 75.8225 30.0004 75.8985 30.3534 75.905C30.7063 75.9115 31.0571 75.8484 31.3856 75.7192C31.7141 75.59 32.0139 75.3972 32.2678 75.152C32.2918 75.129 32.3149 75.106 32.3379 75.082C33.4649 73.901 59.9238 46.02 59.9238 30.295C59.9237 26.4182 59.16 22.5794 57.6761 18.9977C56.1923 15.4161 54.0175 12.1618 51.2759 9.42078C48.5343 6.67972 45.2796 4.50557 41.6976 3.02246C38.1157 1.53935 34.2767 0.776321 30.3999 0.776978ZM30.3999 43.712C27.7464 43.7116 25.1524 42.9243 22.9463 41.4498C20.7401 39.9753 19.0208 37.8798 18.0056 35.4281C16.9904 32.9764 16.725 30.2788 17.2429 27.6763C17.7608 25.0738 19.0388 22.6833 20.9153 20.8071C22.7918 18.9309 25.1824 17.6532 27.785 17.1357C30.3876 16.6181 33.0852 16.8839 35.5367 17.8995C37.9883 18.915 40.0837 20.6348 41.5579 22.8411C43.0321 25.0475 43.8188 27.6414 43.8188 30.295C43.819 32.0571 43.472 33.802 42.7976 35.4301C42.1233 37.0581 41.1348 38.5373 39.8887 39.7833C38.6426 41.0292 37.1633 42.0174 35.5352 42.6915C33.907 43.3656 32.1621 43.7124 30.3999 43.712Z"></path>\
                                        </svg>'),
                            */
                            //url: (property.live_price > _this.availability ? _this.unavailableMarkerIcon : _this.defaultMarkerIcon),
                            //url: 'https://cdnjs.cloudflare.com/ajax/libs/foundicons/3.0.0/svgs/fi-marker.svg',
                            //origin: origin,
                            //anchor: anchor,
                            scaledSize: _this.defaultScaledSize
                        }
                })
            },
            selectPoint()
            {
                this.mixinSendMutation('setGeo',{
                    latitude:   this.selectedGeo.latitude,
                    longitude:  this.selectedGeo.longitude,
                    matchLevel: this.selectedGeo.matchLevel,
                    label:      this.selectedGeo.label
                })
                this.mixinSendMutation('setLabel',this.selectedGeo.label)
                this.$parent.resetResults()
                this.$parent.getResults()
                this.mixinGoTo('search',{})
            },
            clickedMap(click)
            {
                let _this = this
                _this.showInfo = true

                let latitude = click.latLng.lat(),
                    longitude = click.latLng.lng()

                var params = {
                  apikey: this.here_apikey,
                  prox: latitude + ',' + longitude + ',5000',
                  mode: 'retrieveAreas', // nearest city
                  //mode: 'retrieveAll',
                }


              // reverse geocoding del punto di ricerca
              // se ho errore, faccio alla vecchia maniera con label "Punto di ricerca"
              Axios
                .get(this.reverse_geocode_endpoint, {params})
                .then((response) => {

                  let place = false

                  response.data.Response.View[0].Result.forEach( result => {

                    if(result.MatchLevel == 'city') place = result.Location.Address

                  }) // each results


                  if(!place) place = response.data.Response.View[0].Result[0].Location.Address

                  let label = 'Vicino ';
                  if (place.City){

                    label += place.City
                    if (place.State) label += ', ' + place.State

                  }else{

                    label += place.Label

                  }

                  if (place.Country && place.Country != 'ITA') label += ' (' + place.Country + ')'

                  let mapZoomLevel = _this.$refs.map_list.$mapObject.zoom

                  // cambio il raggio del punto di ricerca in base allo zoom level
                  // (solo grafica, la ricerca segue le sue logiche)
                  _this.$refs.circle.$circleObject.setRadius(1000 * (20 - mapZoomLevel))


                  _this.$refs.circle.$circleObject.setCenter({
                    lat: latitude,
                    lng: longitude
                  })

                  _this.$refs.infoWindow.$infoWindowObject.setPosition({
                    lat: latitude,
                    lng: longitude
                  })

                  _this.selectedGeo = {
                    latitude: latitude,
                    longitude: longitude,
                    matchLevel: 'point',
                    label: label
                  }



                }, (error) => {
                  if (window.LogRocket) window.LogRocket.track('error_mapReverseGeocoder');
                  window.console.log('reverse geocode error')
                  window.console.log(error)

                  let mapZoomLevel = _this.$refs.map_list.$mapObject.zoom

                  // cambio il raggio del punto di ricerca in base allo zoom level
                  // (solo grafica, la ricerca segue le sue logiche)
                  _this.$refs.circle.$circleObject.setRadius(1000 * (20 - mapZoomLevel))


                  _this.$refs.circle.$circleObject.setCenter({
                    lat: latitude,
                    lng: longitude
                  })

                  _this.$refs.infoWindow.$infoWindowObject.setPosition({
                    lat: latitude,
                    lng: longitude
                  })

                  _this.selectedGeo = {
                    latitude: latitude,
                    longitude: longitude,
                    matchLevel: 'point',
                    label: "Punto di ricerca"
                  }

                })


                /*setTimeout(function(){
                  // se si toglie il geocoder inverso, mettere qui dentro il contenuto della then
                  // se non mettiamo un delay l'oggetto della mappa che serve non lo vediamo subito
                },200)*/

            },
            clickedMarker(data)
            {
                this.$refs['slick'].$el.slick.slickGoTo(parseInt(data.slideIndex))
                //mixinGoTo('property',{propertyId: data.propertyId})
            },
            clickedPad()
            {
                //this.$refs['slick'].$el.slick.slickGoTo(parseInt(event.target.dataset.slideIndex))
                window.alert('test')
            },
            slick__next() {
                this.$refs.slick.next();
            },

            slick__prev() {
                this.$refs.slick.prev();
            },

            slick__reInit() {
                // Helpful if you have to deal with v-for to update dynamic lists
                this.$nextTick(() => {
                    this.$refs.slick.reSlick();
                });
            },

            // Events listeners
            slick__handleAfterChange(event, slick, currentSlide) {
                (event, slick, currentSlide)
                this.$Lazyload.lazyLoadHandler()
            },
            slick__handleBeforeChange(event, slick, currentSlide, nextSlide) {
                (event, slick)
                this.markers[currentSlide].zIndex = 1
                this.markers[currentSlide].icon = {
                    //url: this.defaultMarkerIcon,
                    //url: (this.markers[currentSlide].live_price > this.availability ? this.unavailableMarkerIcon : this.defaultMarkerIcon),
                    url: this.getMarker(this.markers[currentSlide].live_price),
                    scaledSize: this.defaultScaledSize
                }
                this.markers[nextSlide].zIndex = 2
                this.markers[nextSlide].icon = {
                    url: this.getMarker(0),
                    scaledSize: this.activeScaledSize
                }
            },
            slick__handleBreakpoint(event, slick, breakpoint) {
                (event, slick, breakpoint)
            },
            slick__handleDestroy(event, slick) {
                (event, slick)
            },
            slick__handleEdge(event, slick, direction) {
                (event, slick, direction)
            },
            slick__handleInit(/*event, slick*/) {
                this.markers[0].zIndex = 2
                this.markers[0].icon.url = this.getMarker(0)
            },
            slick__handleReInit(event, slick) {
                (event, slick)
            },
            slick__handleSetPosition(event, slick) {
                (event, slick)
            },
            slick__handleSwipe(event, slick, direction) {
                (event, slick, direction)
            },
            slick__handleLazyLoaded(event, slick, image, imageSource) {
                (event, slick, image, imageSource)
            },
            slick__handleLazeLoadError(event, slick, image, imageSource) {
                (event, slick, image, imageSource)
            },
            scrollSlider(event) {
                event.preventDefault()
                event.stopPropagation()
                //let deltaX = event.deltaX
                let deltaY = event.deltaY

                if(deltaY > 10)
                {
                    this.slick__next()
                }
                if(deltaY < -10)
                {
                    this.slick__prev()
                }

                return false
                //swindow.console.log(deltaY)
            }
        },
        watch: {
            sortResults: function () {
                let _this = this
                _this.loading = true
                setTimeout(function(){
                    _this.markers = _this.mixinSortResults(_this.markers,_this.sortResults.by,_this.sortResults.order)
                    _this.loading = false
                },50)
            }
        },
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/mixins";
    @import './../../scss/_custom.scss';
    //@import "~slick-carousel/slick/slick.css";
    #map-list
    {
        .map-heading
        {
            display: flex;
            justify-content: space-between;
            align-items: center;
            //padding:0 0 1rem 0;
            margin-bottom:2rem;
            padding-right:0;
            font-size:$font-size-base;
            color:$black;
            font-weight: 400;
            .btn-back *
            {
                font-weight:600;
            }
            @include media-breakpoint-up(lg)
            {
                //padding:1rem 0 2rem 0;
                font-size:$font-size-lg;
            }
        }
        .map-wrapper,
        .slick-wrapper
        {
            margin-bottom:1.5rem;
            padding-left:0;
            padding-right:0;
            @include media-breakpoint-up(lg)
            {
                margin-bottom:0;
                padding-left:$grid-gutter-width/2;
                padding-right:$grid-gutter-width/2;
                &.map-wrapper
                {
                    padding-left:0;
                }
            }
        }
        .vue-map-container
        {
            height:260px;
        }
        .slick-container
        {
            position:relative;
            overflow:hidden;

            @include media-breakpoint-down(xl){
                margin-top: 20px;
            }

            @include media-breakpoint-up(xl){
                padding-top: $grid-gutter-width/2;
                padding-bottom: $grid-gutter-width/2;
            }
        }

        .property__box
        {
            justify-content:flex-start;
            @include media-breakpoint-up(lg)
            {
                justify-content: center;
            }
        }

        .property__inner
        {
            height:auto;
            border-radius: 20px;
            overflow: hidden;
            min-height: 500px;
            margin-bottom: 0;

            @include media-breakpoint-down(xl)
            {
                margin-bottom: 0;
            }
        }        

        .property__name
        {
            min-height: 0px;
            @include media-breakpoint-down(md)
            {
                font-size: $font-size-sm;
            }
        }

        .property__link
        {
            @include media-breakpoint-down(md)
            {
                display:none;
            }
        }

        .slick-arrow
        {
            position:absolute;
            z-index: 1;
            text-align:center;
            cursor:pointer;
            background:rgba($white,0.45);
            text-align: center;
        }

        // horizontal slider => tablet landscape and up (min-width:992px)
        .slick-slider:not(.slick-vertical)
        {
            .property__box
            {
                width:300px;
                max-width: 70vw;
                margin:0 0.5rem;
            }

            .slick-slide
            {
                padding:0.75rem 1rem 0.75rem 1rem;
                border-radius: $border-radius-large;
            }       
            .property__inner
            {
                margin-bottom:0;
            }     

            .pad-wrapper
            {
                position:absolute;
                //background:rgba($white,0.5);
                top:0;
                left:0;
                width:100%;
                height:100%;
            }            
            .slick-track
            {
                display: flex !important;
            }
            .slick-slide
            {
                position:relative;
                height: auto;
                transition:$transition-base;
                &:not(.slick-current)
                {
                    opacity:0.5;
                }
                &.slick-current .pad-wrapper
                {
                    display:none !important;
                }
                &>div,
                &>div > div
                {
                    height:100%;
                }
            }
            &~#slick-prev
            {
                left:0;
                bottom:0;
                top:0;
                width:40px;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
            &~#slick-next
            {
                right:0;
                bottom:0;
                top:0;
                width:40px;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }

        $box-vertical-height: 700px;

        @include media-breakpoint-up(lg)
        {
            .vue-map-container
            {
                height:$box-vertical-height;
            }
            .property__box
            {
                height:$box-vertical-height;
                padding:2rem 0;
            }
        }

        // vertical slider => tablet portrait and down (max-width:991px)
        .slick-slider.slick-vertical
        {
            height:100%;
            .property__box
            {
                margin-right:0.5rem; // per non tagliare il box-shadow della pad
            }
            &~#slick-prev
            {
                left:0;
                right:0;
                top:0;
                bottom:auto;
                //width:100%;
                svg
                {
                    transform:rotate(90deg);
                }
            }
            &~#slick-next
            {
                left:0;
                right:0;
                bottom:0;
                top:auto;
                //width:100%;
                svg
                {
                    transform:rotate(90deg);
                }
            }
            .slick-slide {
                padding: 15px 10px 15px 5px;
            }
        }


        //aggiustamenti desktop pre-demo
        .property__name,
        .property__prices-total
        {
            font-size:18px;
        }
        .property__city
        {
            font-size: 14px;
            margin-top:12px;
        }
        .property__rates
        {
            margin-top:0;
        }
        .property__amenities
        {
            .amenity
            {
                width:50%;
                font-size:12px;
                margin-bottom:4px;
                //font-weight:normal;
            }
        }
        .property__desc
        {
            border:0;
            margin-top:0;
            font-size: 14px;
            padding-top:0;
            margin-bottom:1rem;
        }

        #slick-prev,
        #slick-next
        {
            //width:auto;
            margin:0 auto;
        }

        @include media-breakpoint-up(lg)
        {
            .slick-container
            {
               
            }
            .map-wrapper
            {
                padding:0 0 0 0;
            }
            .property__info
            {
                flex-direction:column;
            }
            .property__thumb
            {
                width:100%;
                min-height: 180px;
                height:auto;
            }
            .boxed
            {
                width:100%;
                flex-direction: column;
                padding:$grid-gutter-width/4;
                .desc,
                .side
                {
                    width:100%;
                    &:before{display:none;}
                    margin:0;
                    padding:0;
                }
            }
            #property-prices
            {
                display:flex;
                flex-wrap: wrap;
                flex-direction: row;
                hr
                {
                    display:none;
                }
                button
                {
                    width:100%;
                    margin-top:$grid-gutter-width/2;
                }
            }
            .property__resume,
            .property__prices-occupancy
            {
                width:50%;
            }
            .property__prices-per-night
            {
                margin-bottom:0;
            }
        }

        @include media-breakpoint-up (xl) {
            #slick-prev
            {
                transform:rotate(90deg);
            }
            #slick-next
            {
                transform:rotate(90deg);
            }
        }
        
    }

    @include media-breakpoint-up(lg)
    {
        #app.map-list #appHeader
        {
        display:none;
        }
    }    
</style>